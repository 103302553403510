<template>
  <div class="container">
    <h2>Job Application</h2>
    <form @submit.prevent="submitForm">
      <div>
        <label for="jobSummary">Job Summary:</label>
        <textarea id="jobSummary" v-model="jobSummary"></textarea>
      </div>
      <div>
        <label for="companyDescription">Company Description:</label>
        <textarea id="companyDescription" v-model="companyDescription"></textarea>
      </div>
      <div>
        <label for="tasksDeliverables">Tasks and Deliverables:</label>
        <textarea id="tasksDeliverables" v-model="tasksDeliverables"></textarea>
      </div>
      <div>
        <label for="requiredExperience">Required Experience:</label>
        <textarea id="requiredExperience" v-model="requiredExperience"></textarea>
      </div>
      <div>
        <label for="applicationQuestions">Application Questions:</label>
        <textarea id="applicationQuestions" v-model="applicationQuestions"></textarea>
      </div>
      <button type="submit">Submit</button>
    </form>
    <loading-overlay :active="isLoading" :can-cancel="false" :full-page="false"></loading-overlay>
    <div v-if="response" class="popup">
      <div class="popup-content">
        <h3>Your Pitch for TopTal 😉</h3>
        <pre>{{ response }}</pre>
        <button @click="closePopup">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingOverlay from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    LoadingOverlay
  },
  data() {
    return {
      jobSummary: '',
      companyDescription: '',
      tasksDeliverables: '',
      requiredExperience: '',
      applicationQuestions: '',
      isLoading: false,
      response: null,
      apiKey: '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', // Add your ChatGPT API key here
      apiUrl: 'https://api.openai.com/v1/chat/completions' // Add your ChatGPT API endpoint here
    };
  },
  methods: {
    async submitForm() {
      const prompt = `Hey bro, act as an expert according to the job summary: ${this.jobSummary}, company description: ${this.companyDescription}, tasks and deliverables: ${this.tasksDeliverables}, and required experience: ${this.requiredExperience}. Craft me the best pitch so that I can win a job. The pitch should be human-oriented, plagiarism-free, and include some emojis to attain the attention of the job application reviewer and pass it on to the client. Also, please answer the application questions as best as you can to get the job: ${this.applicationQuestions} .`;

      this.isLoading = true;

      try {
        const response = await axios.post(this.apiUrl, {
          model: 'gpt-3.5-turbo', // Add the model name you want to use here
          "messages": [
            {
                "role": "user",
                "content": prompt
            }
          ],
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey}`
          }
        });

        this.response = response.data.choices[0].message.content;
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
    closePopup() {
      this.response = null;
    }
  }
};
</script>

<style>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  resize: vertical;
}

button {
  margin-top: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.popup-content pre {
  white-space: pre-wrap;
}
</style>
